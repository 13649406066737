import React from 'react'
import Modal from '../Modal/Modal';

class Contact extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modalType: '',
      modalContent: '',
      modalShow: false
    };
  }

  _setModal = (img, type ,e) => {
    e.preventDefault();
    this.setState({
        modalType: type,
        modalContent: img,
    })
    this._toggleModal();
  }

  _toggleModal = () => {
    this.setState((prev, props)=> {
      const newState = !prev.modalShow;
      return { modalShow : newState}
    });
  }

  render(){
    return (
      <React.Fragment>
      <section className="section">
        <div className = "container" >
          <h1 className = "title" > Contact Us </h1>
            <div className="columns">
              <div className="column">
                <h2 className="subtitle">We're here &amp; we're free! </h2>
                <img alt="together" src="/img/pic8_1.jpg"></img>
                <p>
                C.A. is concerned solely with the personal recovery and continued sobriety of individual drug addicts who turn to our Fellowship for help.            </p>
                <p><br/></p>
              </div>
              <div className="column">
                <h2 className="subtitle">ANRC Committee</h2>
                <p>
                <br/>
                <a href="mailto:anrc2026niagara@gmail.com">anrc2026niagara@gmail.com</a>
                </p>
                <p><br/></p>

              </div>
            </div>
        </div >
      </section>
      <Modal type={this.state.modalType} content={this.state.modalContent} closeModal={this._toggleModal} modalState={this.state.modalShow} />
      </React.Fragment>
    );
  }
}

export default Contact;
