import React from "react";
import Modal from "../Modal/Modal";

import "./Home.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "",
      modalContent: "",
      modalShow: false
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
              <article className="tile is-child box">
                <p className="title">2026 ATLANTIC NORTH REGIONAL CONVENTION
                </p>
                <p className="subtitle">Niagara Falls, NY April 16 - 19, 2026</p>
                  <figure className="image is-3by3">
                    <img alt="" src="/img/image0.jpeg"></img>
                  </figure>
                  <p>
                    <br></br>
                    Join Us for the Atlantic North Regional Convention of Cocaine Anonymous!
                    <br></br>
                    <br></br>
                    Mark your calendars! From April 16 to 19, 2026, the ANRC will be held in breathtaking Niagara Falls, New York!
                    <br></br><br></br>
                    This unforgettable weekend will bring together members of CA for inspiring speakers, engaging workshops, and fellowship as we celebrate recovery and unity. Whether you’re new to the program or a long-time member, this event is an incredible opportunity to strengthen your sobriety, connect with old friends, make new ones and ultimately help carry the message of this life saving program to the addict who still suffers. <br></br>
                    <br></br>
                    Stay tuned for more details on registration, hotel accommodations, and the exciting program lineup. We can’t wait to see you there!
                    </p><p>
                  </p>

                </article>
              </div>
            </div>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">Registration</p>
                  <p>
                    Stay tuned for more info                    <br></br>
                    <br></br>
                   
                  </p>
                </article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">Hotel</p>
                  <p>
                  DoubleTree by Hilton Hotel <br></br>
                  401 Buffalo Ave <br></br>
                  Niagara Falls, NY 14303 <br></br>
                  United States<br></br>
                  </p>
                </article>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <h1 className="subtitle"> Preamble </h1>
            <h2>
              Cocaine Anonymous is a fellowship of men and women who share their
              experience, strength and hope with each other that they may solve
              their common problem and help others to recover from their
              addiction.The only requirement for membership is a desire to stop
              using cocaine and all other mind‐ altering substances.There are no
              dues or fees for membership; we are fully self‐ supporting through
              our own contributions.We are not allied with any sect,
              denomination, politics, organization or institution.We do not wish
              to engage in any controversy, and we neither endorse nor oppose
              any causes. Our primary purpose is to stay free from cocaine and
              all other mind‐ altering substances and to help others achieve the
              same freedom. We use the Twelve‐ Step recovery Program because it
              has already been proven that the Twelve‐ Step Recovery Program
              works. Adapted with permission from A.A.Grapevine Inc.
            </h2>
          </div>
        </section>
        <Modal
          type={this.state.modalType}
          content={this.state.modalContent}
          closeModal={this._toggleModal}
          modalState={this.state.modalShow}
        />
      </React.Fragment>
    );
  }
}

export default Home;
